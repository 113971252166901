import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { Container, Row, Col } from "react-bootstrap"

import Header from "./header"
import Footer from "./footer"
import "../styles/index.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      contentfulProjectsPage {
        allProjects {
          title
          slug
        }
      }
    }
  `);

  return (
    <>
      <Header projects={data.contentfulProjectsPage.allProjects} />
      <main id="content">{ children }</main>
      <Footer />
    </>
  )
}

export default Layout
