import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import {CopyToClipboard} from 'react-copy-to-clipboard';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {copied: false}
  }

  resetCopyMessage() {
    setTimeout(() => {
      this.setState({copied: false});
    }, 1000);
  }

  render() {
    return(
      <StaticQuery
        query={graphql`
          query {
            allContentfulHomepage {
              edges {
                node {
                  email
                  footerCopy
                }
              }
            }
            allContentfulSocialMedia {
              edges {
                node {
                  siteName
                  url
                }
              }
            }
          }
        `}
        render={data => (<section id="footer">
          <div className="py-4 bg-brown">
            <Container className="my-2">
              <Row>
                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                  <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                    {
                      data.allContentfulSocialMedia.edges.map((socialMedia, index) => {
                        return(
                          <div key={`social-link-${ socialMedia.node.siteName }`}>
                            {
                              index > 0 ?
                              <p className="social-link-divider mx-4 d-inline" key={`social-divider-${index}`}/>
                              :
                              ""
                            }
                            <a href={ socialMedia.node.url } target="_blank" className="social-link copy color-beige" key={`social-link-${index}`}>{ socialMedia.node.siteName }</a>
                          </div>
                        )
                      })
                    }
                  </div>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 6 }} className="d-none d-md-block">
                  <CopyToClipboard text={data.allContentfulHomepage.edges[0].node.email} onCopy={() => {this.setState({copied: true}); this.resetCopyMessage();}}>
                    <div className="email copy color-beige text-right position-relative">
                      <span className="footer-email">
                        { data.allContentfulHomepage.edges[0].node.email }
                      </span>
                      <div className={`footer-email-copied ${this.state.copied ? "copied" : ""}`}>copied!</div>
                    </div>
                  </CopyToClipboard>
                  <div className="email-disclaimer color-beige text-right">{data.allContentfulHomepage.edges[0].node.footerCopy}</div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="py-4 bg-dark-beige d-block d-md-none">
            <Container className="my-2">
              <Row>
                <Col xs="12">
                  <CopyToClipboard text={data.allContentfulHomepage.edges[0].node.email} onCopy={() => {this.setState({copied: true}); this.resetCopyMessage();}}>
                    <div className="email copy color-brown text-center position-relative">
                      <span className="footer-email">
                        { data.allContentfulHomepage.edges[0].node.email }
                      </span>
                      <div className={`footer-email-copied mobile ${this.state.copied ? "copied" : ""}`}>copied!</div>
                    </div>
                  </CopyToClipboard>
                  <div className="email-disclaimer color-brown text-center">{data.allContentfulHomepage.edges[0].node.footerCopy}</div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        )}
      />
    )
  }
}

export default Footer
