import React, { Component } from "react"
import { StaticQuery, useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { menuStatus: false };
  }

  setMenuStatus(status) {
    this.setState({ menuStatus: status });
  }

  render() {
    return(
      <section id="header" className="w-100 vh-100 py-4 py-md-5 position-absolute">
        <div className="top-nav">
          <Container fluid>
            <Row className="d-flex align-items-end align-items-md-start">
              <Col xs={{ span: 8 }} md={{ span: 8, order: "last" }} className="text-md-center pr-2">
                <a className="d-inline-block nav-bar-item wordmark-hover" href="/">
                  <h1 className="color-pink wordmark mb-0">Babe Nation</h1>
                </a>
               </Col>
              <Col xs={{ span: 4 }} md={{ span: 2, order: "first" }} className="d-flex justify-content-end justify-content-md-start">
                <div className="menu-hamburger d-flex flex-column justify-content-between nav-bar-item" onClick={() => {
                  this.setMenuStatus(true);
                }}>
                  <div className="menu-line"></div>
                  <div className="menu-line"></div>
                  <div className="menu-line"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
  
        <div className={`menu ${ this.state.menuStatus ? "clickable" : ""} position-absolute w-100 vh-100`}>
          <Container fluid className="h-100">
            <Row className="h-100">
              <div className="menu-close-overlay" onClick={() => {
                this.setMenuStatus(false);
              }}/>
              <Col xs={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 4 }} className={`menu-clickable ${ this.state.menuStatus ? "" : "hidden"} py-4 py-md-5 bg-green d-flex flex-column justify-content-center justify-content-between`}>
                <div className="d-flex justify-content-end justify-content-md-start">
                  <div className="menu-close position-relative" onClick={() => {
                    this.setMenuStatus(false);
                  }}>
                    <div className="menu-close-line position-absolute w-100"/>
                    <div className="menu-close-line alt position-absolute w-100"/>
                  </div>
                </div>
                <div className="text-center">
                  <a className="menu-link" href="/#about"><p className="my-2">About Us</p></a>
                  <Link className="menu-link" to="/projects"><p className="my-2">Projects</p></Link>
                  {
                    this.props.projects.map((project, index) => 
                      <Link className="menu-link alt" to={`/${project.slug}`} key={`menu-link-${index}`}><p className="my-2 py-1">{ project.title }</p></Link>
                    )
                  }
                  <a className="menu-link" href="/#testimonials"><p className="my-2">Press</p></a>
                  <a className="menu-link" href="#footer"><p className="my-2">Contact Us</p></a>
                </div>
                <div className="text-center"><p className="menu-acronym mb-0">BN</p></div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    )
  }
}

export default Header
